import { EventModelWriter } from 'shisell';

import { fullstoryWriter } from './fullstory';
import { consoleWriter } from './console';
import { verizonWriter } from './verizon';

export * from './fullstory';
export * from './verizon';

export const analyticsWriters: EventModelWriter<void>[] = [
    consoleWriter,
    fullstoryWriter,
    verizonWriter,
];
