import { useEffect } from 'react';
import { Switch as RRDSwitch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isInV2 } from '../../../utils/isInV2';
import { sendVerizonAnalytics } from '../../../analytics';

type Props = React.ComponentProps<typeof RRDSwitch>;

const isV2 = isInV2();

export const AnalyticsSwitch = ({ children }: Props) => {
    const location = useLocation();

    useEffect(() => {
        sendVerizonAnalytics({
            actionId: 'PageView',
            pathName: location.pathname,
        });
    }, [location]);

    return <RRDSwitch>{children}</RRDSwitch>;
};

export const BasicSwitch = ({ children }: Props) => (
    <RRDSwitch>{children}</RRDSwitch>
);

export const Switch = isV2 ? AnalyticsSwitch : BasicSwitch;
