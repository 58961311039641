import { createContext, useContext } from 'react';
import { DailytaskEntry } from '../types';

export const TodaysTipContext = createContext<{
    todaysTip?: DailytaskEntry;
    isTipCompleted: boolean;
    setTipCompleted: () => void;
}>({
    isTipCompleted: false,
    setTipCompleted: () => {},
});

export const useTodaysTip = () => useContext(TodaysTipContext);
