import { AnalyticsEventModel } from 'shisell';
import { SecurityAdvisorBridgeV2 } from '../../bridge';
import { isInV2 } from '../../utils/isInV2';
import { take } from 'rxjs';

const isV2 = isInV2();

const postVerizonAnalytics = async (
    customerRefId: string,
    data: Record<string, string>
) => {
    const analyticsApi = process.env.REACT_APP_SECURITY_CENTER_API;

    if (!analyticsApi) {
        return;
    }

    await fetch(`${analyticsApi}/verizon-analytics`, {
        method: 'POST',
        body: JSON.stringify({ customerRefId, ...data }),
    });
};

export const sendVerizonAnalytics = async (data: Record<string, string>) => {
    SecurityAdvisorBridgeV2.customerRefId$
        .pipe(take(1))
        .subscribe(async (customerRefId: string) => {
            if (!customerRefId) {
                return;
            }

            postVerizonAnalytics(customerRefId, data).catch((e) =>
                console.error(e)
            );
        });
};

export const verizonWriter = (e: AnalyticsEventModel) => {
    if (!isV2) {
        return;
    }

    if (e.Name === 'ViewArticle') {
        sendVerizonAnalytics({
            actionId: 'ViewArticle',
            articleId: e.ExtraData.ArticleId,
        });
    } else if (e.Name === 'ViewTodaysTip') {
        sendVerizonAnalytics({
            actionId: 'ViewTodaysTip',
            todaysTipId: e.ExtraData.TodaysTipId,
        });
    } else if (e.ExtraData.ActionId === 'ViewSecurityQuestionDescription') {
        sendVerizonAnalytics({
            actionId: 'ViewQuestion',
            questionId: e.ExtraData.QuestionId,
        });
    } else if (e.Name === 'ViewGuide') {
        sendVerizonAnalytics({
            actionId: 'ViewGuide',
            guideId: e.ExtraData.GuideId,
        });
    } else if (e.Name === 'PlayVideo') {
        sendVerizonAnalytics({
            actionId: 'PlayVideo',
            videoId: e.ExtraData.VideoId,
        });
    } else if (
        e.ExtraData.ActionId === 'MarkAsHelpful' ||
        e.ExtraData.ActionId === 'MarkAsNotHelpful'
    ) {
        sendVerizonAnalytics({
            actionId: e.ExtraData.ActionId,
            tipId: e.ExtraData.TipSysId,
        });
    }
};
