// @ts-nocheck
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import * as jose from 'jose';
import { exchangeSubjectToken } from '../utils/tokenExchangeHelper';

export class SecurityAdvisorBridge {
    public static readonly accessToken$ = new BehaviorSubject<string>('');
    public static readonly customerRefId$ = new ReplaySubject(1);

    public static readonly signIn = function (accessToken: string) {
        SecurityAdvisorBridge.accessToken$.next(accessToken);
    };
}

export class SecurityAdvisorBridgeV2 {
    public static readonly accessToken$ = new BehaviorSubject<string>('');
    public static readonly customerRefId$ = new ReplaySubject(1);

    private static _getCustomerRefId(verizonAccessToken: string) {
        try {
            const payload = jose.decodeJwt(verizonAccessToken);
            this.customerRefId$.next(payload.customerRefID);
        } catch (error) {
            console.error('error', error);
        }
    }

    private static readonly _signIn = async function (
        verizonAccessToken: string
    ) {
        const JWKS = jose.createRemoteJWKSet(
            new URL(process.env.REACT_APP_ASURION_JWKS_URL!)
        );

        this._getCustomerRefId(verizonAccessToken);

        try {
            const asurionIdToken = await exchangeSubjectToken(
                verizonAccessToken
            );
            await jose.jwtVerify(asurionIdToken, JWKS, {
                issuer: process.env.REACT_APP_ASURION_JWT_ISSUER!,
            });

            this.accessToken$.next(verizonAccessToken);
        } catch (error) {
            console.error('error', error);
            this.accessToken$.next();
        }
    };
    public static get signIn() {
        return SecurityAdvisorBridgeV2._signIn;
    }
}
