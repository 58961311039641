import { useEffect, useState } from 'react';
import { bridge } from '../../bridge';

export const useIsAuthenticated = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const subscription = bridge.accessToken$.subscribe((v: string) =>
            setIsAuthenticated(!!v)
        );
        return () => subscription.unsubscribe();
    });

    return isAuthenticated;
};
