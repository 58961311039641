import { FC, useEffect } from 'react';
import {
    ArticleComposer,
    ContentfulResponse,
} from '@soluto-private/content-component-library';
import { Loader } from '@soluto-private/vds-loaders';
import { RouteChildrenProps } from 'react-router-dom';

import { useContentfulProductArticle } from '../../../contentful';
import { PageLayout } from '../../../core-ui';
import { RoutePath } from '../../../navigation';
import { Breadcrumbs, BreadcrumbProps } from '../../../navigation/components';
import { useAnalytics } from 'react-shisell';

type ArticleBodyProps = {
    article: ContentfulResponse;
};

type ArticlePageProps = {
    id: string;
};

const ArticleHeader: FC<BreadcrumbProps> = ({ breadcrumbs }) => (
    <Breadcrumbs breadcrumbs={breadcrumbs} margin="0 0 0 2rem" />
);

const ArticleBody: FC<ArticleBodyProps> = ({ article }) => (
    <ArticleComposer response={article} vendor="verizon" />
);

export const ArticlePage: FC<RouteChildrenProps<ArticlePageProps>> = ({
    match,
}) => {
    const { id } = match?.params || {};
    const { value, loading } = useContentfulProductArticle(id!);
    const { dispatcher } = useAnalytics();

    useEffect(() => {
        if (!value) {
            return;
        }

        dispatcher.dispatch('ViewArticle', {
            ExtraData: {
                ArticleId: value.sys.id,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return loading || !value ? (
        <Loader active />
    ) : (
        <PageLayout
            margin="1.5rem 0rem"
            pageName={`ArticlePage-${id}`}
            header={
                <ArticleHeader
                    breadcrumbs={[
                        {
                            text: 'Security Advisor',
                            path: RoutePath.SecurityCenter,
                        },
                        { text: value.fields.title },
                    ]}
                />
            }
            body={<ArticleBody article={value} />}
        />
    );
};
