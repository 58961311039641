import { FC, useEffect } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import { useHasOnboarded } from '../../hooks';
import {
    Button,
    ItemList,
    PageLayout,
    Link as CoreLink,
} from '../../../core-ui';
import { RoutePath } from '../../../navigation';

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5em;
`;

const Link = styled(CoreLink)`
    &:link {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }
`;


const StyledLine = styled(Line)`
    margin: 1.5em 0;
`;

const OnboardingHeader: FC = () => (
    <Title size="XLarge">What you can expect</Title>
);

const OnboardingBody: FC = () => (
    <div>
        <Body>
            Powerful tools here to help you cut through the noise of online
            security.
        </Body>
        <StyledLine type="heavy" />
        <ItemList
            items={[
                {
                    text: 'Identify vulnerabilities',
                    description:
                        'Do you know where someone could access your private data? Whether you lose your wallet or have a exposed password, we’ll help reduce your risk.',
                },
                {
                    text: 'Get security recommendations',
                    description:
                        'Daily goals, guides and articles chosen to help you make the best decisions about your online safety.',
                },
                {
                    text: 'Access expert advice 24/7',
                    description:
                        'Experts available to answer all of your security questions.',
                },
            ]}
        />
        <ButtonContainer>
            <Link actionId="HomePageLink" to={RoutePath.Home}>
                <Button actionId="ContinueButton">Continue</Button>
            </Link>
        </ButtonContainer>
    </div>
);

const OnboardingPage: FC = () => {
    const { setOnboarded } = useHasOnboarded();
    useEffect(setOnboarded, [setOnboarded]);
    return (
        <PageLayout
            pageName="OnboardingPage"
            header={<OnboardingHeader />}
            body={<OnboardingBody />}
        />
    );
};

export default OnboardingPage;
